export default [
  {
    title: "Artículos para Vultr",
    body: "<p>He escrito varios artículos técnicos para Vultr, compartiendo mis conocimientos y experiencia en diversas tecnologías y prácticas de desarrollo. Puedes ver mis publicaciones en el siguiente enlace: <a href='https://docs.vultr.com/author/josé-rafael-gutierrez' target='_blank'>https://docs.vultr.com/author/josé-rafael-gutierrez</a>. Estos artículos cubren una amplia gama de temas, desde la configuración de servidores hasta el desarrollo de aplicaciones y la implementación de mejores prácticas en la nube.</p>",
  },
  {
    title: "Decorcerámica - SAP",
    body: "<p>El desarrollo consistió en integrar el sistema de eCommerce Vtex (<a href='https://www.decorceramica.com/' target='_blank'>https://www.decorceramica.com/</a>) con SAP para gestionar el inventario, los precios, la facturación y la logística. La integración se llevó a cabo utilizando PHP/Laravel, VueJS y una base de datos PostgreSQL.</p>",
  },
  {
    title: "FacesCR - Intélisis",
    body: "<p>Se realizó la integración de tienda Vtex (<a href='https://cr.faces.com/' target='_blank'>https://cr.faces.com/</a>) con el ERP Intélisis para gestionar precios, inventario y facturación, además de integrarse con Correos de Costa Rica (<a href='https://correos.go.cr/' target='_blank'>https://correos.go.cr/</a>) para la gestión logística. Se emplearon tecnologías como PHP/Laravel, VueJS y una base de datos MySQL para llevar a cabo esta integración de manera eficiente.</p>",
  },
  {
    title: "SDK Vtex Api",
    body: "<p>Ésta librería o SDK facilita la comunicación versátil con las API's de Vtex y permite el desarrollo de sistemas de integración con mayor facilidad. Esta librería está diseñada para ser utilizada con PHP, Laravel, Symfony y otros frameworks basados en PHP. Se ha implementado el patrón de diseño Adaptador para lograr la integración de manera más sencilla. Puedes encontrarla en mi cuenta de GitHub: <a href='https://github.com/josefo727/vtex-api' target='_blank'>https://github.com/josefo727/vtex-api</a></p>",
  },
  {
    title: "Crédito KBK",
    body: "<p>Se realizó la integración del medio de pago Crédito Karibik en tienda Vtex Legacy, y posteriormente se migró a Vtex IO (<a href='https://www.karibik.co/credito' target='_blank'>https://www.karibik.co/credito</a>). Este sistema de pago se enfoca en brindar créditos a los clientes afiliados a Karibik, y fue configurado con un Middleware de Integración (NodeJS/Express, MongoDB y VueJS) para la gestión de dichos créditos.</p>",
  },
  {
    title: "OT&V de PMI",
    body: "<p>Se desarrolló el sistema Order Tracking and Visualization para Phillips Morris Internacional con una integración a Vtex para inyectar órdenes de diferentes medios de ventas y tipos. El sistema utiliza una orquestación para procesar las órdenes en tiempo real y enviarlas a diferentes carriers para la recolección, según el workflow correspondiente. En el backend se utilizó Laravel, VueJS, MySQL y MasterData, mientras que en el frontend se empleó Vtex IO y ReactJS para los componentes administrativos customizados.</p>",
  },
  {
    title: "Kaiowa",
    body: "<p>Se desarrolló e implementó un operador de medios de crédito para tiendas Vtex afiliadas, permitiendo la gestión de planes de crédito personalizados. El operador fue creado en el BackEnd utilizando PHP/Laravel, VueJS, Inertia y Livewire, con una base de datos PostgreSQL. Para el FrontEnd se creó un Widget para Vtex Legacy y Vtex IO, utilizando Vanilla JS y ReactJS. Este medio de pago ha sido aprobado y homologado por Vtex.</p>",
  },
  {
    title: "Custom Blog The Bar Colombia",
    body: "<p>Se creó para The Bar Colombia un custom blog (<a href='https://co.thebar.com/blog' target='_blank'>https://co.thebar.com/blog</a>). Con backend en NodeJS/Express y MongoDB, la interfaz administrativa fue creada en ReactJS para Vtex en custom components y se integró al Admin de Vtex.</p>",
  },
];
