<template>
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Josefo</span></strong>
      </div>
      <div class="credits">
        Designed by <a href="https://josegutierrez.ck/">José R. Gutierrez</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>