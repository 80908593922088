export default {
    address: 'Carrera 81D #16-19',
    residency: 'Barrio Andalucía',
    home: 'Piso 2, Apto 201',
    city: 'Kennedy',
    department: 'Bogotá DC',
    country: 'Colombia',
    longitude: '4.6546793',
    latitude: '-74.1402691',
}
