import personal from '@/data/personal';

export default {
    namespaced: true,
    state: () => ({
        my: personal,
    }),
    getters: {

    },
    actions: {

    },
    mutations: {

    },
}
