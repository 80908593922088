export default {
  email: "josefo727@gmail.com",
  web_site: "https://jose-gutierrez.com",
  facebook: "https://www.facebook.com/joserafael.gutierrezblanco",
  twitter: "https://twitter.com/Nige81",
  skype: "https://join.skype.com/invite/ByfPA21DyIiR",
  linkedin: "https://www.linkedin.com/in/jose-gutierrez-5a2293128/",
  instagram: "https://www.instagram.com/josefo1981/",
  phone: "+576018394169",
  mobil: "+573026178155",
};
